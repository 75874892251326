import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useAppContext } from '../lib/context';
import { ReactComponent as CautionIcon } from '../assets/svg/caution.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check.svg';

export default function Notifications() {
  const { notifications, setNotifications } = useAppContext();

  return (
    <ToastContainer position='top-center' className='p-3 z-full'>
      <Toast
        show={notifications !== null}
        onClose={() => setNotifications(null)}
        delay={5000}
        autohide
      >
        <Toast.Header
          closeButton={false}
          className={`bg-${notifications?.icon}`}
        >
          {notifications?.icon === 'danger' && (
            <CautionIcon height='24' className='text-white me-3' />
          )}
          {notifications?.icon === 'success' && (
            <CheckIcon height='24' className='text-white me-3' />
          )}
          <strong className='me-auto text-white'>{notifications?.title}</strong>
        </Toast.Header>
        <Toast.Body>
          <ul className='list-unstyled'>
            {notifications?.messages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
