import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { handleWindowResize } from './tools';
import { useLogoutMutation } from './mutations';
// eslint-disable-next-line import/no-cycle
import { getCurrentUser } from './queries';

const AppContext = createContext([]);

export const useAppContext = () => useContext(AppContext);

export function AppWrapper({ children }) {
  const [actusFilterCategory, setActusFilterCategory] = useState('');
  const [actusSearch, setActusSearch] = useState('');
  const [advertisementsSearch, setAdvertisementsSearch] = useState('');
  const [attachments, setAttachments] = useState(1);
  const [autoDiagData, setAutoDiagData] = useState();
  const [autoDiagSectionsError, setAutoDiagSectionsError] = useState();
  const [device, setDevice] = useState();
  const [documentsReceivedFilterCategory, setDocumentsReceivedFilterCategory] =
    useState('');
  const [documentsReceivedFilterType, setDocumentsReceivedFilterType] =
    useState('');
  const [documentsReceivedSearch, setDocumentsReceivedSearch] = useState('');
  const [historySearch, setHistorySearch] = useState('');
  const [holdingSearch, setHoldingSearch] = useState('');
  const [holdingFilterIsAuditRequired, setHoldingFilterIsAuditRequired] =
    useState('');
  const [holdingFilterIsLabelling, setHoldingFilterIsLabelling] = useState('');
  const [importantInfoSearch, setImportantInfoSearch] = useState('');
  const [messagesSearch, setMessagesSearch] = useState('');
  const [myDocumentsSearch, setMyDocumentsSearch] = useState('');
  const [notifications, setNotifications] = useState(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [selectedHoldingIds, setSelectedHoldingIds] = useState([]);
  const [user, setUser] = useState();
  const [userDoc, setUserDoc] = useState(1);
  const [planningData, setPlanningData] = useState();
  const [notCurrentYearPlanningData, setNotCurrentYearPlanningData] =
    useState();
  const [planningChange, setPlanningChange] = useState(false);
  const [videosSearch, setVideosSearch] = useState('');
  const [yearPlanning, setYearPlanning] = useState(new Date().getFullYear());
  const navigate = useNavigate();
  const logoutMutation = useLogoutMutation();

  const setUserContext = async () => {
    const currentUser = await getCurrentUser();
    setUser(currentUser);
    localStorage.setItem('isUserLogged', true);
    localStorage.setItem('role', currentUser.role);
    if (currentUser.breeder) {
      localStorage.setItem('holding', currentUser.breeder.holding);
    }
    return currentUser;
  };

  useEffect(() => {
    handleWindowResize(setDevice);
    window.onresize = () => handleWindowResize(setDevice);
  }, []);

  const logout = () => {
    logoutMutation.mutate('', {
      onSuccess: () => {
        localStorage.clear();
        navigate('/login/', { replace: true });
      },
    });
  };

  const providerValue = useMemo(
    () => ({
      actusFilterCategory,
      actusSearch,
      advertisementsSearch,
      attachments,
      autoDiagData,
      autoDiagSectionsError,
      device,
      documentsReceivedFilterCategory,
      documentsReceivedFilterType,
      documentsReceivedSearch,
      historySearch,
      holdingFilterIsAuditRequired,
      holdingFilterIsLabelling,
      holdingSearch,
      importantInfoSearch,
      logout,
      messagesSearch,
      myDocumentsSearch,
      notCurrentYearPlanningData,
      notifications,
      planningChange,
      planningData,
      selectedDocumentIds,
      selectedHoldingIds,
      setActusSearch,
      setActusFilterCategory,
      setAdvertisementsSearch,
      setAttachments,
      setAutoDiagData,
      setAutoDiagSectionsError,
      setDocumentsReceivedFilterCategory,
      setDocumentsReceivedFilterType,
      setDocumentsReceivedSearch,
      setHistorySearch,
      setHoldingFilterIsAuditRequired,
      setHoldingFilterIsLabelling,
      setHoldingSearch,
      setImportantInfoSearch,
      setMessagesSearch,
      setMyDocumentsSearch,
      setNotCurrentYearPlanningData,
      setNotifications,
      setPlanningChange,
      setPlanningData,
      setSelectedDocumentIds,
      setSelectedHoldingIds,
      setUserContext,
      setUserDoc,
      setVideosSearch,
      setYearPlanning,
      user,
      userDoc,
      videosSearch,
      yearPlanning,
    }),
    [
      actusFilterCategory,
      actusSearch,
      advertisementsSearch,
      attachments,
      autoDiagData,
      autoDiagSectionsError,
      device,
      documentsReceivedFilterCategory,
      documentsReceivedFilterType,
      documentsReceivedSearch,
      historySearch,
      holdingFilterIsAuditRequired,
      holdingFilterIsLabelling,
      holdingSearch,
      importantInfoSearch,
      messagesSearch,
      myDocumentsSearch,
      notCurrentYearPlanningData,
      notifications,
      planningChange,
      planningData,
      selectedDocumentIds,
      selectedHoldingIds,
      user,
      userDoc,
      videosSearch,
      yearPlanning,
    ]
  );
  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
