import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function PrivateRoute({ admin, auditor, children }) {
  const location = useLocation();

  if (!localStorage.getItem('isUserLogged')) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login.
    return <Navigate to='/login/' state={{ from: location }} replace />;
  }
  if (admin && !auditor && localStorage.getItem('role') !== 'administrator') {
    return <Navigate to='/404/' replace />;
  }
  if (auditor && !admin && localStorage.getItem('role') !== 'auditor') {
    return <Navigate to='/404/' replace />;
  }
  if (
    admin &&
    auditor &&
    localStorage.getItem('role') !== 'administrator' &&
    localStorage.getItem('role') !== 'auditor'
  ) {
    return <Navigate to='/404/' replace />;
  }
  return children;
}

PrivateRoute.propTypes = {
  admin: PropTypes.bool,
  auditor: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PrivateRoute.defaultProps = {
  admin: false,
  auditor: false,
};
