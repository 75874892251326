import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';

import queryClient from './lib/react-query';
import { AppWrapper } from './lib/context';
import AppRoutes from './routes';
import Notifications from './components/Notifications';
import { getCookie } from './lib/tools';
import Loader from './components/Loader';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default function App() {
  axios.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.patch['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');
  axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken');

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Suspense
          fallback={
            <div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
              <Loader size='xl' />
            </div>
          }
        >
          <AppWrapper>
            <Notifications />
            <AppRoutes />
          </AppWrapper>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}
